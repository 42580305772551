// Импортируем js-файлы

// Функция для прокрутки страницы вверх
function scrollToTop() {
    if (window.scrollY !== 0) {
        // Если не вверху страницы, прокручиваем страницу к верху
        window.scrollTo({top: 0, behavior: 'smooth'});
    }
}

// Найти кнопку "Наверх"
const backButton = document.querySelector('.back-to-top');

// Добавить обработчик события при клике на кнопку
backButton.addEventListener('click', (e) => {
    e.preventDefault(); // Предотвратить переход по ссылке
    scrollToTop(); // Вызвать функцию прокрутки к верху страницы
});

// Добавить обработчик события при скроллинге страницы
window.addEventListener('scroll', () => {
    if (window.scrollY > window.innerHeight) {
        // Если прокрутка больше высоты экрана, добавить класс "active"
        backButton.classList.add('active');
    } else {
        // В противном случае удалить класс "active"
        backButton.classList.remove('active');
    }
});

document.addEventListener('DOMContentLoaded', function() {
    var dropdownToggles = document.querySelectorAll('.nav-link.dropdown-toggle');

    dropdownToggles.forEach(function(toggle) {
        toggle.addEventListener('click', function(e) {
            // Предотвращаем стандартное поведение dropdown
            e.preventDefault();
            e.stopPropagation();

            // Получаем URL из атрибута href
            var url = this.getAttribute('href');

            // Проверяем, есть ли URL
            if (url) {
                // Если URL существует, переходим по нему
                window.location.href = url;
            }
        });
    });
});